import moment from "moment";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters} from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ApiService from "@/core/services/api.service";
import SubscriptionApiService from "@/core/services/api.service.subscription";

export default {
    props : {
        modal : {
            default : false
        } ,
        value : Boolean
    } ,

    name : "promotion" ,
    data() {
        return {
            requiredRule : [v => !!v || "Field is required"] ,
            requiredCity : [
                v => {
                    if (!this.promotion.allCities) {
                        return !!v || 'Field is required';
                    } else return true;
                } ,
            ] ,
            maxCountRules : [
                v => {
                    if (this.promotion.type === "SPECIAL_OFFER") {
                        if (v != null) {
                            if (v<4) {
                                return 'Max count must NOT be below 4!';
                            } else return true;
                        } else return 'Max count is required';
                    } else return true;
                } ,
            ] ,
            isSaving : false ,
            promotionId : null ,
            valid : false ,
            loading : false ,
            shopsSearchInput : null ,
            shopsWithCounts : [] ,
            citiesIsLoading : false ,
            cityId : null ,
            categories: [],
            categoriesTree: [],
            selectedCategories:[],
            promotion : {
                title : "" ,
                active : false ,
                description : "" ,
                start : null ,
                end : null ,
                type : "" ,
                link : "" ,
                base64image : "" ,
                thumbnailPath : "" ,
                shops : [] ,
                titleTypeDefinition : null ,
                cityId : null ,
                allCities : false,
                beforeEventBanner: null,
                duringEventBanner: null,
                categories: []
            } ,
            titleTypeDefinitions : [] ,
            shops : [] ,
            shopIdsWithPromotions : [] ,
            cities : [] ,
            types : [
                "HAPPY_HOUR" ,
                "OFFER_OF_THE_DAY" ,
                "SPECIAL_OFFER" ,
                "SHOPPING_EVENT"
            ] ,
            search : "" ,
            shopsIsLoading : false ,
            titleTypesIsLoading : false ,
            bannerDefinitions: [],
            bannersIsLoading: false,
            dateFromMenu : false ,
            dateToMenu : false
        };
    } ,
    async created() {
        await SubscriptionApiService.init ();
        this.promotionId = this.$route.params.promotionId;
        let title =
            this.promotionId != null ? "Promotion details" : "Insert new promotion";
        this.$store.dispatch ( SET_BREADCRUMB , [{ title : title }] );
        if (this.promotionId != null) {
            ApiService.get ( `api/promotion/with-counts` , `${this.promotionId}` )
                .then ( response => {
                    this.$log.debug ( "Promotion: " , response.data );
                    this.promotion = response.data.promotionDef;
                    if (this.promotion.city != null) {
                        this.cityId = this.promotion.city.id;
                    }

                    if(this.promotion.categories != null && this.promotion.categories.length > 0 && typeof this.promotion.categories[0] != 'number'){
                        this.selectedCategories = this.promotion.categories.map((obj) => obj.id);
                    }

                    let rows = [];
                    for (var i in response.data.shopWithMaxCount) {
                        let newRow = {};
                        newRow["shop"] = parseInt ( i );
                        newRow["maxCount"] = response.data.shopWithMaxCount[i];
                        rows.push ( newRow );
                    }

                    this.shopsWithCounts = rows;
                } )
                .catch ( error => {
                    this.$log.error ( "Error: " , error );
                    this.errored = true;
                } )
                .finally ( () => {
                        this.loading = false;
                        this.searchShops ();
                    }
                );
        }
        this.searchTitleTypeDefinitions ();
        this.searchCities ();
        this.searchMainPageBannerDefinitions();
        this.searchCategories();
    } ,
    methods : {
        listToTree(list){
            let map = {}, node, roots = [], i;

            for (i = 0; i < list.length; i += 1) {
                map[list[i].id] = i; // initialize the map
            }

            for (i = 0; i < list.length; i += 1) {
                node = list[i];
                if (node.parentId !== null && node.parentId !== "0") {
                    // if you have dangling branches check that map[node.parentId] exists
                    if (typeof  list[map[node.parentId]].children !== 'undefined' &&  list[map[node.parentId]].children.length >= 0) {
                        list[map[node.parentId]].children.push(node);
                    }else{
                        list[map[node.parentId]].children = []; // initialize the children
                        list[map[node.parentId]].children.push(node);
                    }
                } else {
                    roots.push(node);
                }
            }
            this.categoriesTree = roots;
        },
        searchCategories() {
            // Items have already been loaded
            if (this.categories.length > 0) return;

            // Items have already been requested
            if (this.categoriesIsLoading) return;

            this.categoriesIsLoading = true;
            ApiService.query("api/shop-categories")
                .then(res => {
                    this.categories = res.data;
                    this.listToTree(this.categories);

                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.errored = true;
                })
                .finally(() => (this.categoriesIsLoading = false));
        },
        searchMainPageBannerDefinitions() {
            // Items have already been loaded
            if (this.bannerDefinitions.length > 0) return;

            // Items have already been requested
            if (this.bannersIsLoading) return;

            this.bannersIsLoading = true;
            let params = {};
            params["page"] = 1;
            params["size"] = 999;
            ApiService.query("api/banner-definition/main-page", {
                params
            })
                .then(res => {
                    this.bannerDefinitions = res.data;
                })
                .catch(error => {
                    this.$log.error("Error: ", error);
                    this.errored = true;
                })
                .finally(() => (this.bannersIsLoading = false));
        },
        searchCities() {
            // Items have already been loaded
            if (this.cities.length>0) return;

            // Items have already been requested
            if (this.citiesIsLoading) return;

            this.citiesIsLoading = true;
            ApiService.query ( "api/cities" )
                .then ( res => {
                    this.cities = res.data;
                } )
                .catch ( error => {
                    this.$log.error ( "Error: " , error );
                    this.errored = true;
                } )
                .finally ( () => (this.citiesIsLoading = false) );
        } ,
        removeRowFromGrid(item) {
            this.shopsWithCounts.splice ( this.shopsWithCounts.indexOf ( item ) , 1 );
        } ,
        addEmptyRowToGrid() {
            let newRow = {};
            this.shopsWithCounts.push ( newRow );
        } ,
        previousState() {
            this.$router.go ( -1 );
        } ,
        endsWith(suffix , str) {
            return str.indexOf ( suffix , str.length - suffix.length ) !== -1;
        } ,
        paddingSize(value) {
            if (this.endsWith ( "==" , value )) {
                return 2;
            }
            if (this.endsWith ( "=" , value )) {
                return 1;
            }
            return 0;
        } ,
        size(value) {
            return (value.length / 4) * 3 - this.paddingSize ( value );
        } ,
        byteSize(base64String) {
            return this.formatAsBytes ( this.size ( base64String ) );
        } ,
        formatAsBytes(size) {
            return size.toString ().replace ( /\B(?=(\d{3})+(?!\d))/g , " " ) + " bytes";
        } ,
        handleImage(event) {
            if (event && event.target.files && event.target.files[0]) {
                const file = event.target.files[0];
                // this.toBase64(file);
                this.toBase64 ( file , base64Data => {
                    this.promotion.base64image = base64Data;
                } );
            }
        } ,
        toBase64(file , cb) {
            const fileReader = new FileReader ();
            fileReader.onload = e => {
                const base64Data = e.target.result;
                cb ( base64Data );
            };
            fileReader.readAsDataURL ( file );
        } ,
        remove(item) {
            const index = this.promotion.shops.indexOf ( item.id );
            if (index>=0) this.promotion.shops.splice ( index , 1 );
        } ,
        searchTitleTypeDefinitions() {
            // Items have already been loaded
            if (this.titleTypeDefinitions.length>0) return;

            // Items have already been requested
            if (this.titleTypesIsLoading) return;

            this.titleTypesIsLoading = true;
            let params = {};
            params["page"] = 1;
            params["size"] = 999;
            ApiService.query ( "api/promotion-title-definition" , {
                params
            } )
                .then ( res => {
                    this.titleTypeDefinitions = res.data.content;
                } )
                .catch ( error => {
                    this.$log.error ( "Error: " , error );
                    this.errored = true;
                } )
                .finally ( () => (this.titleTypesIsLoading = false) );
        } ,
        searchShops() {
            // PromotionType is not selected
            if (this.promotion.type === "") return;
            this.shops = [];
            // Items have already been requested
            if (this.shopsIsLoading) return;
            this.shopsIsLoading = true;
            let params = {};
            params["promotionType"] = this.promotion.type;
            ApiService.query ( "api/shops/promotion-shops" , { params } )
                .then ( res => {
                    this.shops = res.data;
                } )
                .catch ( error => {
                    this.$log.error ( "Error: " , error );
                    this.errored = true;
                } )
                .finally ( () => (this.shopsIsLoading = false) );
        } ,
        isNonEmptyArrayOfStrings(value) {
            return (
                Array.isArray ( value ) &&
                value.length &&
                value.every ( item => typeof item === "number" )
            );
        } ,
        validateAndSave() {
            if (this.$refs.form.validate ()) {
                console.log ( 'validate' , this.$refs.form.validate () )
                let errors = []
                let invalidMaxCount = false;
                console.log ( 'shopsWithCounts:' + this.shopsWithCounts )
                if (this.promotion.type === "SPECIAL_OFFER") {
                    this.shopsWithCounts.forEach ( (elem) => {
                        console.log ( "maxCount:" + elem.maxCount )
                        if (elem.maxCount === undefined || elem.maxCount<4) {
                            invalidMaxCount = true;
                            errors.push (
                                `Max count for shop ${
                                    elem.shop.name
                                    } is less than 4!`
                            )
                        }
                        console.log ( 'Errors' , errors )
                    } )
                }

                if (!invalidMaxCount) this.savePromotion ( this.promotion );
            }
        } ,
        savePromotion(promotion) {
            this.loading = true;
            this.isSaving = true;

            if(typeof promotion.beforeEventBanner === 'number'){
                let beforeEventBanner = {};
                beforeEventBanner['id'] = promotion.beforeEventBanner;
                promotion.beforeEventBanner = beforeEventBanner;
            }
            if(typeof promotion.duringEventBanner === 'number'){
                let duringEventBanner = {};
                duringEventBanner['id'] = promotion.duringEventBanner;
                promotion.duringEventBanner = duringEventBanner;
            }

            let categories = [];
            this.selectedCategories.forEach(function(entry) {
                let category = {};
                category['id'] = entry;
                categories.push(category);
            });
            promotion.categories = categories;

            let hashMap = new Object ();
            promotion.shops = this.shopsWithCounts.map ( function (el) {
                hashMap[el.shop] = el.maxCount;
                return { id : el.shop };
            } );
            let promotionWithHashMap = new Object ();
            console.log ( "cityId:" + this.cityId )
            if (this.cityId != null && !this.promotion.allCities) {
                let city = new Object ();
                city["id"] = this.cityId;
                promotion["city"] = city;

            }
            if (this.promotion.allCities) {
                promotion["city"] = null;
            }
            promotionWithHashMap["promotionDef"] = promotion;
            promotionWithHashMap["shopWithMaxCount"] = hashMap;
            if (promotion.id != null) {
                ApiService.put ( `api/promotion/with-counts` , promotionWithHashMap )
                    .then ( response => {
                        this.$log.debug ( "Promotion updated: " + response );
                        this.$emit ( "promotionSaved" , "promotionSaved" );
                    } )
                    .catch ( error => {
                        this.$log.error ( "Error: " , error );
                        this.errored = true;
                    } )
                    .finally ( () => {
                        this.loading = false;
                        this.isSaving = false;
                        this.previousState ();
                    } );
            } else {
                ApiService.post ( `api/promotion/with-counts` , promotionWithHashMap )
                    .then ( response => {
                        this.$log.debug ( "Promotion created: " + response );
                        this.$emit ( "promotionSaved" , "promotionSaved" );
                    } )
                    .catch ( error => {
                        this.$log.error ( "Error: " , error );
                        this.errored = true;
                    } )
                    .finally ( () => {
                        this.loading = false;
                        this.isSaving = false;
                        this.previousState ();
                    } );
            }
        }
    } ,
    computed : {
        shopTableHeaders() {
            return [
                { text : this.$i18n.t ( 'common.shop' ) , value : "shop" , sortable : false } ,
                { text : this.$i18n.t ( 'common.max' ) , value : "maxCount" , sortable : false } ,
                { text : this.$i18n.t ( 'common.delete' ) , value : "delete" , sortable : false }
            ]
        } ,
        ...mapGetters ( ["currentUserPersonalInfo"] ) ,
        ...mapGetters ( ["currentUser"] ) ,
        show : {
            get() {
                return this.value;
            } ,
            set(value) {
                if (!value) {
                    this.clearFields ();
                    this.$emit ( "input" , value );
                }
            }
        } ,
        dateStartFormatted : {
            get() {
                if (this.promotion.start == null || this.promotion.start === "")
                    return null;
                return moment
                    .utc ( this.promotion.start )
                    .local ()
                    .format ( "yyyy-MM-DD HH:mm:ss" );
            } ,
            set(value) {
                this.promotion.start = value;
            }
        } ,
        dateEndFormatted : {
            get() {
                if (this.promotion.end == null || this.promotion.start === "")
                    return null;
                return moment
                    .utc ( this.promotion.end )
                    .local ()
                    .format ( "yyyy-MM-DD HH:mm:ss" );
            } ,
            set(value) {
                this.promotion.end = value;
            }
        } ,
        isAddToTableDisabled() {
            let buttonDisabled =
                this.promotion.type === "SPECIAL_OFFER" &&
                this.shopsWithCounts.length>0;
            return buttonDisabled;
        } ,
        disableSubmit() {
            let buttonDisabled =
                this.promotion.type === "SPECIAL_OFFER" &&
                this.shopsWithCounts.length>1;
            return buttonDisabled;
        }
    }
};
